/** @format */

import { fromJS } from 'immutable';
const NUMBER_OF_VDS = 4;

const INITIAL_STATE = fromJS(getInitialState());

export default function vdsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CONFIGURATION_EDIT_INPUT_CHANGE': {
      const { index, type, name, params, value } = action.payload;
      if (index !== undefined && type === 'vds') {
        if (name === 'generateEncryptionKey') {
          return state.setIn([index, 'encryptionKey'], 'TODO');
        } else if (name === 'pollInterval' && value) {
          return state.setIn([index, name], value).setIn([index, 'supervisionInterval'], value * 3);
        } else if (name === 'lan' && value) {
          return state.setIn([index, name], Number(value));
        } else if (name === 'active' && value && params) {
          state = state.setIn([index, 'supervisionSequenceChannel'], params)
        }
        return state.setIn([index, name], value);
      } else if (index !== undefined && type === 'vdsOutputs') {
        return state.setIn([index, 'vdsOutputs', params.index, name], value);
      }
      return state;
    }
    default: {
      return state;
    }
  }
}

function getInitialVds(position) {
  return {
    position,
    direction: 'going',
    address: '',
    port: 0,
    pollInterval: 0,
    supervisionInterval: 0,
    supervisionSequenceChannel: '',
    active: false,
    vdsOutputs: getInitialVdsOutputs()
  };
}

function getInitialState() {
  const INITIAL_VDS_JSON = [];
  for (let i = 0; i < NUMBER_OF_VDS; i++) {
    const position = i + 1;
    INITIAL_VDS_JSON.push(getInitialVds(position));
  }
  return INITIAL_VDS_JSON;
}

function getInitialVdsOutputs() {
  const vdsOutputsDefaults = {
    sumAlarm: 240,
    intrCrit: 244,
    intrNonCrit: 243,
    comFail: 241,
    bufferFull: 242,
    mainsFail: 245,
    battFail: 246
  };
  const INITIAL_VDS_OUTPUTS_JSON = [];
  Object.keys(vdsOutputsDefaults).forEach((f) => {
    INITIAL_VDS_OUTPUTS_JSON.push({
      active: false,
      function: f,
      vdsOutputNumber: vdsOutputsDefaults[f]
    });
  });
  return INITIAL_VDS_OUTPUTS_JSON;
}
