/** @format */

import { fromJS } from 'immutable';
import createFolder from './createFolder';
import shareFolder from './shareFolder';
import shareExternal from './shareExternal';
import deleteFolder from './deleteFolder';
import moveFolder from './moveFolder';
import renameFolder from './renameFolder';
import hardwareReset from './hardwareReset';
import deleteConfiguration from './deleteConfiguration';
import moveConfiguration from './moveConfiguration';
import exportConfiguration from './exportConfiguration';
import shareConfiguration from './shareConfiguration';
import editShare from './editShare';
import removeShare from './removeShare';
import firmwareUpgrade from './firmwareUpgrade';
import kernelUpgrade from './kernelUpgrade';
import mobileModemFirmwareUpgrade from './mobileModemFirmwareUpgrade';
import simCardUpload from './simCardUpload';
import deleteUser from './deleteUser';
import simCardAutoExtend from './simCardAutoExtend';
import organizationDowngrade from './organizationDowngrade';
import activateUser from './activateUser';
import changeProduct from './changeProduct';
import addUser from './addUser';
import programming from './programming';
import editAuthenticationCode from './editAuthenticationCode';
import warningBeforeCommission from './warningBeforeCommission';
import warningBeforeReboot from './warningBeforeReboot';
import warningBeforeAlarmTest from './warningBeforeAlarmTest';
import alarmTestResult from './alarmTestResult';
import importUsers from './importUsers';
import createNewConfiguration from './createNewConfiguration';
import testEagleEyeReceiver from './testEagleEyeReceiver';
import reactivateUser from './reactivateUser';
import vpn from './vpn';
import resendUser from './resendUser';
import warningNoInvoiceInformation from './warningNoInvoiceInformation';
import thingTerminate from './thingTerminate';
import activateThing from './activateThing';
import firmwareInterval from './firmwareInterval';
import downloadCsvFile from './downloadCsvFile';
import createConfigurationStep2 from './createConfigurationStep2';
import eagleEye from './eagleEye';
import policy from './policy';
import forceUpgrade from './forceUpgrade';
import warningApn4GSupportInformation from './warningApn4GSupportInformation';
import activateEagleEye from './activateEagleEye';
import billingPeriod from './billingPeriod';
import noReceiversWarning from './noReceiversWarning';
import editInvoiceReference from './editInvoiceReference';
import editAdminNotes from './editAdminNotes';
import sequenceChannelSpecificationUpload from './sequenceChannelSpecificationUpload';
import voucher from './voucher';
import editInvoiceInfo from './editInvoiceInfo';
import deleteOrganization from './deleteOrganization';
import removeWanMonitoringItem from './removeWanMonitoringItem';
import prefixHelp from './prefixHelp';
import dhcpLeases from './dhcpLeases';
import blockedEmails from './blockedEmails';
import featureKey from './featureKey';
import arpTable from './arpTable';

const INITIAL_STATE = fromJS({
  createFolder: createFolder(undefined, { type: '' }),
  shareFolder: shareFolder(undefined, { type: '' }),
  deleteFolder: deleteFolder(undefined, { type: '' }),
  moveFolder: moveFolder(undefined, { type: '' }),
  renameFolder: renameFolder(undefined, { type: '' }),
  hardwareReset: hardwareReset(undefined, { type: '' }),
  deleteConfiguration: deleteConfiguration(undefined, { type: '' }),
  exportConfiguration: exportConfiguration(undefined, { type: '' }),
  moveConfiguration: moveConfiguration(undefined, { type: '' }),
  shareConfiguration: shareConfiguration(undefined, { type: '' }),
  editShare: editShare(undefined, { type: '' }),
  removeShare: removeShare(undefined, { type: '' }),
  firmwareUpgrade: firmwareUpgrade(undefined, { type: '' }),
  kernelUpgrade: kernelUpgrade(undefined, { type: '' }),
  mobileModemFirmwareUpgrade: mobileModemFirmwareUpgrade(undefined, {
    type: ''
  }),
  deleteUser: deleteUser(undefined, { type: '' }),
  reactivateUser: reactivateUser(undefined, { type: '' }),
  resendUser: resendUser(undefined, { type: '' }),
  simCardUpload: simCardUpload(undefined, { type: '' }),
  simCardAutoExtend: simCardAutoExtend(undefined, { type: '' }),
  organizationDowngrade: organizationDowngrade(undefined, { type: '' }),
  activateUser: activateUser(undefined, { type: '' }),
  changeProduct: changeProduct(undefined, { type: '' }),
  addUser: addUser(undefined, { type: '' }),
  programming: programming(undefined, { type: '' }),
  editAuthenticationCode: editAuthenticationCode(undefined, { type: '' }),
  warningBeforeCommission: warningBeforeCommission(undefined, { type: '' }),
  warningBeforeReboot: warningBeforeReboot(undefined, { type: '' }),
  warningBeforeAlarmTest: warningBeforeAlarmTest(undefined, { type: '' }),
  alarmTestResult: alarmTestResult(undefined, { type: '' }),
  importUsers: importUsers(undefined, { type: '' }),
  createNewConfiguration: createNewConfiguration(undefined, { type: '' }),
  testEagleEyeReceiver: testEagleEyeReceiver(undefined, { type: '' }),
  vpn: vpn(undefined, { type: '' }),
  warningNoInvoiceInformation: warningNoInvoiceInformation(undefined, {
    type: ''
  }),
  thingTerminate: thingTerminate(undefined, { type: '' }),
  activateThing: activateThing(undefined, { type: '' }),
  firmwareInterval: firmwareInterval(undefined, { type: '' }),
  downloadCsvFile: downloadCsvFile(undefined, { type: '' }),
  createConfigurationStep2: createConfigurationStep2(undefined, { type: '' }),
  eagleEye: eagleEye(undefined, { type: '' }),
  policy: policy(undefined, { type: '' }),
  forceUpgrade: forceUpgrade(undefined, { type: '' }),
  WarningApn4GSupportInformation: warningApn4GSupportInformation(undefined, {
    type: ''
  }),
  activateEagleEye: activateEagleEye(undefined, { type: '' }),
  billingPeriod: billingPeriod(undefined, { type: '' }),
  noReceiversWarning: noReceiversWarning(undefined, { type: '' }),
  editInvoiceReference: editInvoiceReference(undefined, { type: '' }),
  editAdminNotes: editAdminNotes(undefined, { type: '' }),
  voucher: voucher(undefined, { type: '' }),
  sequenceChannelSpecificationUpload: sequenceChannelSpecificationUpload(
    undefined,
    { type: '' }
  ),
  editInvoiceInfo: editInvoiceInfo(undefined, { type: '' }),
  deleteOrganization: deleteOrganization(undefined, { type: '' }),
  removeWanMonitoringItem: removeWanMonitoringItem(undefined, { type: '' }),
  prefixHelp: prefixHelp(undefined, { type: '' }),
  dhcpLeases: dhcpLeases(undefined, { type: '' }),
  blockedEmails: blockedEmails(undefined, { type: '' }),
  featureKey: featureKey(undefined, { type: '' }),
  arpTable: arpTable(undefined, { type: '' })
});

export default function modalsReducer(state = INITIAL_STATE, action) {
  state = state.set(
    'createFolder',
    createFolder(state.get('createFolder'), action)
  );
  state = state.set(
    'shareFolder',
    shareFolder(state.get('shareFolder'), action)
  );
  state = state.set(
    'shareExternal',
    shareExternal(state.get('shareExternal'), action)
  );
  state = state.set(
    'deleteFolder',
    deleteFolder(state.get('deleteFolder'), action)
  );
  state = state.set('moveFolder', moveFolder(state.get('moveFolder'), action));
  state = state.set(
    'renameFolder',
    renameFolder(state.get('renameFolder'), action)
  );
  state = state.set(
    'hardwareReset',
    hardwareReset(state.get('hardwareReset'), action)
  );
  state = state.set(
    'deleteConfiguration',
    deleteConfiguration(state.get('deleteConfiguration'), action)
  );
  state = state.set(
    'exportConfiguration',
    exportConfiguration(state.get('exportConfiguration'), action)
  );
  state = state.set(
    'moveConfiguration',
    moveConfiguration(state.get('moveConfiguration'), action)
  );
  state = state.set(
    'shareConfiguration',
    shareConfiguration(state.get('shareConfiguration'), action)
  );
  state = state.set('editShare', editShare(state.get('editShare'), action));
  state = state.set(
    'removeShare',
    removeShare(state.get('removeShare'), action)
  );
  state = state.set(
    'firmwareUpgrade',
    firmwareUpgrade(state.get('firmwareUpgrade'), action)
  );
  state = state.set(
    'kernelUpgrade',
    kernelUpgrade(state.get('kernelUpgrade'), action)
  );
  state = state.set(
    'mobileModemFirmwareUpgrade',
    mobileModemFirmwareUpgrade(state.get('mobileModemFirmwareUpgrade'), action)
  );
  state = state.set(
    'simCardUpload',
    simCardUpload(state.get('simCardUpload'), action)
  );
  state = state.set('deleteUser', deleteUser(state.get('deleteUser'), action));
  state = state.set(
    'reactivateUser',
    reactivateUser(state.get('reactivateUser'), action)
  );
  state = state.set('resendUser', resendUser(state.get('resendUser'), action));
  state = state.set(
    'simCardAutoExtend',
    simCardAutoExtend(state.get('simCardAutoExtend'), action)
  );
  state = state.set(
    'organizationDowngrade',
    organizationDowngrade(state.get('organizationDowngrade'), action)
  );
  state = state.set(
    'activateUser',
    activateUser(state.get('activateUser'), action)
  );
  state = state.set(
    'changeProduct',
    changeProduct(state.get('changeProduct'), action)
  );
  state = state.set('addUser', addUser(state.get('addUser'), action));
  state = state.set(
    'programming',
    programming(state.get('programming'), action)
  );
  state = state.set(
    'editAuthenticationCode',
    editAuthenticationCode(state.get('editAuthenticationCode'), action)
  );
  state = state.set(
    'warningBeforeCommission',
    warningBeforeCommission(state.get('warningBeforeCommission'), action)
  );
  state = state.set(
    'warningBeforeReboot',
    warningBeforeReboot(state.get('warningBeforeReboot'), action)
  );
  state = state.set(
    'warningBeforeAlarmTest',
    warningBeforeAlarmTest(state.get('warningBeforeAlarmTest'), action)
  );
  state = state.set(
    'alarmTestResult',
    alarmTestResult(state.get('alarmTestResult'), action)
  );
  state = state.set(
    'importUsers',
    importUsers(state.get('importUsers'), action)
  );
  state = state.set(
    'createNewConfiguration',
    createNewConfiguration(state.get('createNewConfiguration'), action)
  );
  state = state.set(
    'testEagleEyeReceiver',
    testEagleEyeReceiver(state.get('testEagleEyeReceiver'), action)
  );
  state = state.set('vpn', vpn(state.get('vpn'), action));
  state = state.set(
    'warningNoInvoiceInformation',
    warningNoInvoiceInformation(
      state.get('warningNoInvoiceInformation'),
      action
    )
  );
  state = state.set(
    'thingTerminate',
    thingTerminate(state.get('thingTerminate'), action)
  );
  state = state.set(
    'activateThing',
    activateThing(state.get('activateThing'), action)
  );
  state = state.set(
    'firmwareInterval',
    firmwareInterval(state.get('firmwareInterval'), action)
  );
  state = state.set(
    'downloadCsvFile',
    downloadCsvFile(state.get('downloadCsvFile'), action)
  );
  state = state.set(
    'createConfigurationStep2',
    createConfigurationStep2(state.get('createConfigurationStep2'), action)
  );
  state = state.set('eagleEye', eagleEye(state.get('eagleEye'), action));
  state = state.set('policy', policy(state.get('policy'), action));
  state = state.set(
    'forceUpgrade',
    forceUpgrade(state.get('forceUpgrade'), action)
  );
  state = state.set(
    'warningApn4GSupportInformation',
    warningApn4GSupportInformation(
      state.get('warningApn4GSupportInformation'),
      action
    )
  );
  state = state.set(
    'activateEagleEye',
    activateEagleEye(state.get('activateEagleEye'), action)
  );
  state = state.set(
    'billingPeriod',
    billingPeriod(state.get('billingPeriod'), action)
  );
  state = state.set(
    'activateEagleEye',
    activateEagleEye(state.get('activateEagleEye'), action)
  );
  state = state.set(
    'billingPeriod',
    billingPeriod(state.get('billingPeriod'), action)
  );
  state = state.set(
    'noReceiversWarning',
    noReceiversWarning(state.get('noReceiversWarning'), action)
  );
  state = state.set(
    'activateEagleEye',
    activateEagleEye(state.get('activateEagleEye'), action)
  );
  state = state.set(
    'billingPeriod',
    billingPeriod(state.get('billingPeriod'), action)
  );
  state = state.set(
    'noReceiversWarning',
    noReceiversWarning(state.get('noReceiversWarning'), action)
  );
  state = state.set(
    'editInvoiceReference',
    editInvoiceReference(state.get('editInvoiceReference'), action)
  );
  state = state.set(
    'editAdminNotes',
    editAdminNotes(state.get('editAdminNotes'), action)
  );
  state = state.set('voucher', voucher(state.get('voucher'), action));
  state = state.set(
    'sequenceChannelSpecificationUpload',
    sequenceChannelSpecificationUpload(
      state.get('sequenceChannelSpecificationUpload'),
      action
    )
  );
  state = state.set(
    'editInvoiceInfo',
    editInvoiceInfo(state.get('editInvoiceInfo'), action)
  );
  state = state.set(
    'deleteOrganization',
    deleteOrganization(state.get('deleteOrganization'), action)
  );
  state = state.set(
    'removeWanMonitoringItem',
    removeWanMonitoringItem(state.get('removeWanMonitoringItem'), action)
  );
  state = state.set('prefixHelp', prefixHelp(state.get('prefixHelp'), action));
  state = state.set('dhcpLeases', dhcpLeases(state.get('dhcpLeases'), action));
  state = state.set(
    'blockedEmails',
    blockedEmails(state.get('blockedEmails'), action)
  );
  state = state.set('featureKey', featureKey(state.get('featureKey'), action));
  state = state.set('arpTable', arpTable(state.get('arpTable'), action));
  switch (action.type) {
    case 'SESSION_LOGOUT_DONE': {
      return INITIAL_STATE;
    }
    default: {
      return state;
    }
  }
}
