/** @format */

import React from 'react';

const Dialog = ({
  title,
  description,
  confirmText,
  onConfirmClick,
  abortText,
  onAbortClick,
  children,
  hideAbort,
  hideConfirm,
  error,
  className,
  disableConfirm,
  disableAbort,
  abortClassName
}) => {
  return (
    <div className={`dualtech-dialog-container ${className || ''}`}>
      <div
        className={`dualtech-dialog ${className || ''} ${
          hideConfirm && hideAbort ? 'smaller-bottom-padding' : ''
        }`}
      >
        <h2>{title}</h2>
        <p>{description}</p>
        <div className={`content ${children ? 'has-children' : ''}`}>
          {children}
        </div>
        <p className='error'>{error}</p>
      </div>
      {hideConfirm && hideAbort ? null : (
        <div className='action-container'>
          <div className='actions'>
            {hideConfirm ? null : (
              <button
                type='button'
                onClick={onConfirmClick}
                disabled={disableConfirm}
              >
                {confirmText}
              </button>
            )}
            {hideAbort ? null : (
              <button
                type='button'
                className={abortClassName || ''}
                onClick={onAbortClick}
                disabled={disableAbort}
              >
                {abortText}
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dialog;
