/** @format */

import { fromJS } from 'immutable';
const NUMBER_OF_INPUTS = 8;

const INITIAL_STATE = fromJS(getInitialState());

export default function inputsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CONFIGURATION_EDIT_INPUT_CHANGE': {
      const { index, type, name, params, value } = action.payload;
      if (index !== undefined && type === 'inputs') {
        if (name === 'flickerDetectionCheckbox') {
          if (value) {
            state = state
              .setIn([index, 'flickerCounter'], params.get('flickerCounter'))
              .setIn(
                [index, 'flickerDetectTime'],
                params.get('flickerDetectTime')
              )
              .setIn([index, 'flickerModeTime'], params.get('flickerModeTime'));
          } else {
            state = state
              .setIn([index, 'flickerCounter'], null)
              .setIn([index, 'flickerDetectTime'], null)
              .setIn([index, 'flickerModeTime'], null);
          }
        }
        if (name === 'sequenceChannel') {
          const position = state.getIn([index, 'position']);
          const currentEventCode = state.getIn([index, 'eventCode']);
          const currentSequenceChannel = state.getIn([
            index,
            'sequenceChannel'
          ]);
          const { eventCode: defaultEventCode } = getExtendedSettingsDc09(
            position,
            currentSequenceChannel
          );
          if (currentEventCode === defaultEventCode) {
            const { eventCode: newEventCode } = getExtendedSettingsDc09(
              position,
              value
            );
            state = state.setIn([index, 'eventCode'], newEventCode);
          }
        }
        if (name === 'inputType' && value && value.includes('Vds')) {
          state = state
            .setIn([index, 'flickerCounter'], null)
            .setIn([index, 'flickerDetectTime'], null)
            .setIn([index, 'flickerModeTime'], null)
            .setIn([index, 'flickerDetectionCheckbox'], false)
            .setIn([index, 'vdsInputNumber'], state.getIn([index, 'position']));
        }
        return state.setIn([index, name], value);
      } else if (action.payload.name === 'expansionBoard') {
        if (action.payload.value === 'none') {
          state = state.filter((input) => input.get('position') < 9);
        } else if (state.size < 9) {
          for (let i = 8; i < 16; i++) {
            const position = i + 1;
            const extendedSettings = state.getIn([0, 'v4'])
              ? getExtendedSettingsV4(position)
              : getExtendedSettingsDc09(position);
            state = state.push(
              fromJS({
                ...getInitialInput(position),
                ...extendedSettings
              })
            );
          }
        }
      } else if (action.payload.name === 'product') {
        state = state.filter((input) => input.get('position') < 9);
      } else if (
        index !== undefined &&
        type === 'vds' &&
        name === 'active' &&
        !value
      ) {
        const inputs = state.toJS();
        inputs.forEach((input) => {
          if (
            input.inputType &&
            input.inputType.includes('Vds' + (index + 1))
          ) {
            input.inputType = 'normallyClosedNone';
          }
        });
        return fromJS(inputs);
      }
      return state;
    }
    case 'EAGLE_EYE_REMOTE_CONTROL_UPDATE': {
      return state.map((oldInput) => {
        const updatedInput = action.payload.inputs.find(
          (input) => input.position === oldInput.get('position')
        );
        return updatedInput ? fromJS(updatedInput) : oldInput;
      });
    }
    case 'CONFIGURATION_EDIT_TOGGLE_ALARM_CENTER_INPUT': {
      const previousProtocol = action.payload.value;
      if (action.payload.name === 'hasDc09' && previousProtocol === 'v4') {
        return state.map((input) =>
          input.mergeDeep(
            fromJS(
              getExtendedSettingsDc09(
                input.get('position'),
                input.get('sequenceChannel')
              )
            ),
            { v4: false }
          )
        );
      }
      if (action.payload.name === 'hasDc09' && previousProtocol === 'dc09') {
        return state.map((input) =>
          input.mergeDeep(
            fromJS(getExtendedSettingsV4(input.get('position'))),
            { v4: true }
          )
        );
      }
      return state;
    }
    case 'EDIT_CONFIGURATION_PREDEFINED_VALUES': {
      const { dialerProtocol, alarmCenter, notChangeSaved } = action.payload;
      const eventCodes = alarmCenter && alarmCenter.get('eventCodes');
      return eventCodes
        ? setPredefinedValues({
          inputs: state,
          eventCodes,
          dialerProtocol,
          notChangeSaved
        })
        : state;
    }
    default: {
      return state;
    }
  }
}

function getExtendedSettingsV4(position) {
  return {
    eventCode: `0${position}`,
    sectionText: 'Section 1',
    sectionNumber: '01'
  };
}

function getExtendedSettingsDc09(position, sequenceChannel = '') {
  return {
    eventCode: `UA/UR${sequenceChannel && `;${sequenceChannel}`}`,
    sectionText: `Section ${position}`,
    sectionNumber: `000${position}`
  };
}

function getInitialInput(position) {
  return {
    text: '',
    position,
    label: '',
    flickerCounter: null,
    flickerDetectTime: null,
    flickerModeTime: null,
    inputType: null,
    sequenceChannel: '',
    delay: 0,
    delayType: 'P',
    twentyFourHourZone: false,
    sendReset: true,
    resetDefinition: 'N',
    showExtendedBody: false,
    reservedFields: []
  };
}

function getInitialState() {
  const INITIAL_INPUTS_JSON = [];
  for (let i = 0; i < NUMBER_OF_INPUTS; i++) {
    const position = i + 1;
    INITIAL_INPUTS_JSON.push({
      ...getInitialInput(position),
      ...getExtendedSettingsV4(position),
      v4: true
    });
  }
  return INITIAL_INPUTS_JSON;
}

function setPredefinedValues({
  inputs,
  eventCodes,
  dialerProtocol,
  notChangeSaved
}) {
  const errorInputPositions = [9, 10, 11, 13, 20, 39];
  return inputs.map((input) => {
    if (notChangeSaved && input.get('id')) return input;
    const inputEventCodes = eventCodes
      .filter((match) => !errorInputPositions.includes(match.get('position')))
      .find((match) => match.get('position') === input.get('position'));

    if (!inputEventCodes) {
      return input;
    }

    const reservedFields = [];
    const editable = inputEventCodes.get('editable');
    const eventCode = inputEventCodes.get('eventCode');
    const sectionText = inputEventCodes.get('sectionText');
    const sectionNumber = inputEventCodes.get('sectionNumber');

    if (!editable) {
      reservedFields.push('eventCode', 'sectionText', 'sectionNumber');
    }

    if (dialerProtocol !== 'Contact ID') {
      return input
        .set('reservedFields', fromJS(reservedFields))
        .set('eventCode', eventCode)
        .set('sectionText', sectionText)
        .set('sectionNumber', sectionNumber);
    }

    const cidEventCode = inputEventCodes.getIn(['cid', 'eventCode']);
    const cidSectionNumber = inputEventCodes.getIn(['cid', 'sectionNumber']);

    return input
      .set('reservedFields', fromJS(reservedFields))
      .set('sectionText', sectionText)
      .set('eventCode', cidEventCode || eventCode)
      .set('sectionNumber', cidSectionNumber || sectionNumber);
  });
}
